@charset "utf-8";
body {
  /* margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-y: scroll;
}
* {
  -webkit-tap-highlight-color: transparent; /* 터치 하이라이트 제거*/
  -webkit-overflow-scrolling: touch; /* ios 스크롤 스무스*/
  -ms-overflow-style: none; /* IE에서 스크롤바 감춤*/
}

::-webkit-scrollbar {
  display: none !important; /* 윈도우 크롬 등 */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

nav {
  padding: 20px;
  background-color: rgb(55, 71, 79);
  overflow: scroll;
}

nav a {
  margin: 10px;
}

.hidden {
  visibility: hidden;
}
.noneDisplay {
  display: none;
}
.open {
  display: block;
}

.p05p {
  padding: 5%;
}

.p1 {
  padding: 1px;
}

.p05 {
  padding: 0.5em;
}

.ml05 {
  margin-left: 0.5em;
}

.ml1 {
  margin-left: 1em;
}

.m12 {
  margin-left: 2em;
}

.mb10 {
  margin-bottom: 10px;
}

.mb05 {
  margin-bottom: 0.5em;
}

.ml10 {
  margin-left: 10px;
}

.p10 {
  padding: 10px;
}

.mr02 {
  margin-right: 0.2em;
}

.mr1 {
  margin-right: 1em;
}

.mr2 {
  margin-right: 2em;
}

.flex {
  display: flex;
  align-items: center;
}

.wid100 {
  width: 100%;
}

.wid50 {
  width: 50%;
}

.maxw57 {
  max-width: 57vw;
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}

/* .video {
  .sssss: hover {
    opacity: 0.5;
  }
} */
